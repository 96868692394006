.margin-0 {
  margin: 0;
}
.flex-basis-100-percent {
  flex-basis: 100%;
}

.height-width-1-point-3em {
  height: 1.3em;
  width: 1.3em;
}
.display-inline-block {
  display: inline-block;
}
.dimmed-overlay {
  background: rgba(0, 0, 0, 0.25);
}
