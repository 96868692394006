.fade-in-fade-out-transition-enter {
  opacity: 0;
}
.fade-in-fade-out-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-in-fade-out-transition-exit {
  opacity: 1;
}
.fade-in-fade-out-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.fhir-container__ResourceContainer__card {
  display: none;
}
/* --- media queries --- */
@media screen and (min-width: 576px) {
  :root {
    --spacing: 12px;
  }

  .font-size-smaller {
    font-size: 15px;
  }

  .font-size-smaller.letter-spacing-large {
    letter-spacing: 0.6px;
  }

  .font-size-small {
    font-size: 18px;
  }

  .font-size-medium {
    font-size: 24px;
  }

  .font-size-large {
    font-size: 24px;
  }

  .font-size-larger {
    font-size: 30px;
  }

  .fixed-header-padding {
    padding-top: 120px;
  }

  .top-right {
    top: 120px;
    right: 24px;
  }

  .remaining-body-height {
    height: calc(100% - 96px);
  }

  .remaining-height-actions-header {
    height: calc(100% - 107px);
  }
}
