.grey-abstract-background-image {
  background-image: url("./assets/images/greyStripBackground.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
}

.letter-spacing-4-percentage {
  letter-spacing: 0.6px;
}

.notification-remaining-height {
  height: calc(100% - 38px);
}

.height-85-percentage {
  height: 85%;
}

/* --- media queries --- */
@media screen and (min-width: 576px) {
  :root {
    --spacing: 12px;
  }

  .font-size-smaller {
    font-size: 15px;
  }

  .font-size-smaller.letter-spacing-large {
    letter-spacing: 0.6px;
  }

  .font-size-small {
    font-size: 18px;
  }

  .font-size-medium {
    font-size: 24px;
  }

  .font-size-large {
    font-size: 24px;
  }

  .font-size-larger {
    font-size: 30px;
  }

  .fixed-header-padding {
    padding-top: 120px;
  }

  .top-right {
    top: 120px;
    right: 24px;
  }

  .remaining-body-height {
    height: calc(100% - 96px);
  }

  .remaining-height-actions-header {
    height: calc(100% - 107px);
  }
}

.max-height-70px {
  max-height: 70px;
}
