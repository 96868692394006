.background-color-approved {
  color: #00a000;
  background: rgba(0, 160, 0, 0.12);
}

.status-denied-background-color {
  background: rgba(230, 69, 57, 0.12);
}

.status-pending-background-color {
  background: rgba(166, 169, 0, 0.12);
  color: #a6a900;
}
