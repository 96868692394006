.height-width-0-point-5em {
  height: 0.8em;
  width: 0.8em;
}
.border-2px-lite-grey {
  border: 2px solid #e5e5e5;
}

.min-height-48px {
  min-height: 48px;
}
.max-height-100px {
  max-height: 100px;
}

.max-height-128px {
  max-height: 128px;
}

.max-height-50px {
  max-height: 50px;
}

.gap-0-point-5em {
  gap: 0.5em;
}

.z-index-2 {
  z-index: 2;
}

.list-style-type-none {
  list-style-type: none;
}

.select-suffix-icon {
  position: absolute;
  top: 20px;
  right: 10px;
  transform: translateY(-35%);
}

.select-suffix-icon {
  height: 0.5em;
  width: 0.5em;
  transform-origin: center;
  transition: transform 0.3s;
  transform: rotate(90deg);
}

.select-suffix-icon.disabled {
  right: 5px;
}

.top-30-percentage {
  top: 30%;
}

.height-width-1em {
  height: 1em;
  width: 1em;
}

.max-height-40vh {
  max-height: 40vh;
}

.select {
  background-color: transparent;
}

.select-padding {
  max-width: 100%;
  padding-top: 1em;
  padding-right: 2em;
  padding-bottom: 0.4em;
  padding-left: 0px;
}

.select-padding.disabled {
  padding-left: 5px;
}

.select-border-bottom-default {
  border: none;
  border-bottom: 1px solid #757575;
}

.select:focus {
  outline: none;
}

.select:required {
  box-shadow: none;
}

.select-label {
  font-size: 16px;
  pointer-events: none;
  top: 55%;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  text-transform: uppercase;
  transform: translateY(-50%);
  color: var(--secondaryColor);
}

.select-label.active {
  top: 12px;
  font-size: 10px;
  color: var(--secondaryColor);
}

.select-label.active.disabled {
  left: 5px;
}

.select-border-bottom {
  position: relative;
  display: block;
  width: 100%;
}

.border-bottom-1px-solid-grey {
  border-bottom: 1px solid #757575;
}

.background-color-black-with-opacity-light {
  background: rgba(0, 0, 0, 0.25);
}

.animated-bottom-modal-backdrop {
  background-color: #0000004b;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
}

.animated-bottom-modal-anime {
  transform: translateY(100%);
  transition: transform 0.15s;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.align-items-flex-end {
  align-items: flex-end;
}

.animated-bottom-modal-anime-entrance {
  transform: translateY(0);
}

.height-50vh {
  height: 50vh;
}

.height-70vh {
  height: 70vh;
}

.flex-basis-70-percentage {
  flex-basis: 70%;
}

.flex-basis-80-percentage {
  flex-basis: 80%;
}

.border-bottom-2px-lite-grey {
  border-bottom: 2px solid #e5e5e5;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

@media screen and (min-width: 576px) {
  .select-label.active {
    font-size: 15px;
  }
}
