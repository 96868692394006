.width-33-percentage {
  width: 32%;
}

.voice-recorder-contol-button-size {
  width: 44px;
  height: 44px;
}

.flex-basis-80-percentage {
  flex-basis: 80%;
}

.control-icon-width {
  height: 16px;
}

.list-style-type-none {
	list-style-type: none;
}

.column-gap-3-percentage{
  column-gap: 3%;
}

.max-height-90-percentage {
	max-height: 90%;
}
.object-fit-contain{
	object-fit: contain;
}

.width-30-percentage{
  width: 30%;
}

.width-40-percentage{
  width: 40%;
}

@media screen and (min-width: 576px) {
  .control-icon-width {
    height: 24px;
  }

  .voice-recorder-contol-button-size {
    width: 64px;
    height: 64px;
  } 
}
