.pdf-body {
  border-bottom: grey 1px solid;;
}

.background-color-quaternary {
  background-color: #e5e5e5;
}

.height-80vh {
  height: 80vh;
}

.height-60vh {
  height: 60vh;
}

.height-10vh {
  height: 10vh;
}

.width-80percentage {
  width: 80%;
}

.inherit-height {
  height: inherit;
}

.drop-down-arrow-width {
  height: 6px;
  transform: rotate(0deg);
  transition: transform 300ms;
}

.custom-drop-down-height {
  height: 30px;
  overflow: hidden;
  transition: height 300ms ease;
}

.custom-drop-down-height-active {
  height: max-content;
  transition: height 300ms ease;
}

.rotate-drop-down-arrow {
  transform: rotate(180deg);
  transform-origin: initial;
  transition: transform 300ms;
}

.pdf-add-notes-button-size {
  height: 34px;
  width: 34px;
}

.add-notes-icon-size {
  height: 12px;
  width: 12px;
}

@media screen and (min-width: 575px) {
  .custom-drop-down-height {
    height: 45px;
    overflow: hidden;
    transition: height 300ms ease;
  }

  .custom-drop-down-height-active {
    height: max-content;
    transition: height 300ms ease;
  }

  .drop-down-arrow-width {
    height: 8px;
    transform: rotate(0deg);
    transform-origin: initial;
    transition: transform 300ms;
  }

  .rotate-drop-down-arrow {
    transform: rotate(180deg);
    transition: transform 300ms;
  }

  .pdf-add-notes-button-size {
    height: 51px;
    width: 51px;
  }
  .add-notes-icon-size {
    height: 24px;
    width: 24px;
  }
}
