.clinic-connection-remove-icon-height {
  height: 12px;
}

.thin-border {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}

.text-align-default {
  text-align: initial;
}


@media screen and (min-width: 576px) {
  .clinic-connection-remove-icon-height {
    height: 18px;
  }
}


.width-max-content {
  width: max-content;
}