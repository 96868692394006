.filter-icon-height-and-width {
  height: 32px;
  width: auto;
}

.overflow-horizontal-scroll {
  overflow-x: scroll;
}

.overflow-horizontal-scroll::-webkit-scrollbar {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

.overflow-horizontal-scroll::-webkit-scrollbar-track {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

.overflow-horizontal-scroll::-webkit-scrollbar-thumb {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

.max-width-550px {
  max-width: 550px;
}

.font-size-8px {
  font-size: 8px;
}

.box-shadow-secondary {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.border-left-right-1px {
  border-right: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
}

.display-none {
  display: none;
}

.home-upload-icon-height {
  height: 16px;
}

.flex-basis-30-percentage {
  flex-basis: 30%;
}

.flex-basis-for-documents-card {
  flex-basis: 75%;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.text-overflow-elilipsis {
  text-overflow: ellipsis;
}

.filter-icon-height-and-width:hover {
  background: lighten(#f44336, 10%);
  transition: all 0.5s;
}

.filter-icon-height-and-width:active {
  background: lighten(#f44336, 5%);
  transition: all 0.5s;
}

.width-75-percentage {
  width: 75%;
}

.home-add-documents-icon-size {
  height: 32px;
}

.document-card-timestamp-max-width {
  min-width: 65%;
}

.line-height-100-percentage {
  line-height: 100%;
}

.height-and-width-44px {
  height: 44px;
  width: 44px;
}
.height-500-px {
  height: 500px;
}

.height-398-px {
  height: 398px;
}

.border-right-1px {
  border-right: 1px solid #f1f1f1;
}

.header-height {
  height: 64px;
}

.height-width-14px {
  height: 14px;
  width: 14px;
}

.height-width-16px {
  height: 16px;
  width: 16px;
}

.border-collapse {
  border-collapse: separate;
}

.border-radius-4px {
  border-radius: 4px;
}

.top-20-percent {
  top: 20%;
}

.max-width-900px {
  max-width: 900px;
}

.max-width-630px {
  max-width: 630px;
}

.profiles-card-section-max-height {
  max-height: 128px;
}

.header-logo-height {
  height: 24px;
}

.support-icon-height {
  height: 16px;
}

.back-arrow-row-icon-size {
  height: 18px;
}

.settings-icon-height {
  height: 16px;
}

.check-mark-icon-height {
  height: 12px;
}

.profile-card-height {
  height: 64px;
}

.notifications-section-max-height {
  max-height: 200px;
}

.border-bottom-radius-8px {
  border-radius: 0 0 8px 8px;
}

.border-top-radius-8px {
  border-radius: 0 0 8px 8px;
}

.height-and-width-for-single-digit-number {
  height: 14px;
  width: 14px;
}

.height-and-width-for-double-digit-number {
  height: 16px;
  width: 16px;
  border-radius: 10px;
}

.height-and-width-for-triple-digit-number {
  height: 16px;
  line-height: 16px;
  width: 20px;
  border-radius: 10px;
}

@media screen and (max-width: 320px) {
  .notifications-section-max-height {
    max-height: 190px;
  }

  .flex-basis-for-documents-card {
    flex-basis: 80%;
  }

  .document-card-timestamp-max-width {
    min-width: 60%;
  }
}

@media screen and (min-width: 575px) {
  .home-upload-icon-height {
    height: 24px;
  }

  .filter-icon-height-and-width {
    height: 42px;
    width: auto;
  }

  .notifications-section-max-height {
    max-height: 273px;
  }

  .profile-card-height {
    height: 96px;
  }

  .header-height {
    height: 96px;
  }

  .header-logo-height {
    height: 34px;
  }

  .support-icon-height {
    height: 24px;
  }

  .back-arrow-row-icon-size {
    height: 21px;
  }

  .settings-icon-height {
    height: 24px;
  }

  .check-mark-icon-height {
    height: 17px;
  }

  .profiles-card-section-max-height {
    max-height: 186px;
  }

  .height-and-width-for-single-digit-number {
    height: 17px;
    width: 17px;
  }

  .height-and-width-for-double-digit-number {
    height: 23px;
    width: 24px;
  }

  .height-and-width-for-triple-digit-number {
    height: 23px;
    width: 30px;
  }
}

.bottom-15px {
  bottom: 15px;
}

.bottom-75px {
  bottom: 75px;
}

.right-15px {
  right: 15px;
}
