.settings-menu-icon-size {
  height: 32px;
  width: 32px;
  transition: background 350ms;
}

.active-menu-border-bottom-2px-green {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #00a000;
}

.menu-border-bottom-2px {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid transparent;
}

.settings-menu-icon-height {
  height: 16px;
}

.border-radius-small {
  border-radius: 4px;
}

.settings-primary-profile-horizontal-margin {
  margin-left: 15px;
  margin-right: 15px;
}

.settings-secondary-profile-horizontal-margin {
  margin-left: 14px;
  margin-right: 14px;
}

.settings-add-profile-icon-height {
  height: 32px;
}

.settings-add-profile-active-icon-height {
  height: 44px;
}

.settings-notification-icon-count-size {
  height: 12px;
  width: 12px;
}

.font-size-extra-small {
  font-size: 8px;
}

.settings-notification-icon-count-size {
  bottom: 4px;
  left: 4px;
}

.settings-menu-horizontal-ruler {
  height: 1px;
  background-color: #e5e5e5;
  margin-top: -1px;
}

.border-1px-e5e5e5 {
  border: 1px solid #e5e5e5;
}

.border-bottom-1px-faded-gray li {
  border-bottom: 1px solid #e5e5e5;
}

.border-bottom-1px-faded-gray li:last-child {
  border-bottom: none;
}

.settings-screen-content-remaining-height {
  height: calc(100% - 135px);
}

.height-90-percentage {
  height: 90%;
}

.profiles-section-height {
  height: 50px;
}

.thin-border {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}

.text-align-default {
  text-align: initial;
}

.width-330px {
  width: 330px;
}

.height-width-5em {
  height: 5em;
  width: 5em;
}

.float-left {
  float: left;
}

.height-0P8em {
  height: 0.8em;
}

.width-20percentage {
  width: 20%;
}

.width-30percentage {
  width: 30%;
}

.width-50percentage {
  width: 50%;
}

.width-80percentage {
  width: 80%;
}

.max-height-900px {
  max-height: 900px;
}

.height-90-percent {
  height: 90%;
}

.flex-justify-content-space-around {
  display: flex;
  justify-content: space-around;
}

.max-height-width-400px {
  max-width: 400px;
  max-height: 400px;
}

.width-150px {
  width: 150px;
}

.circle {
  border-radius: 50%;
}

.crop-modal-width {
  max-width: 400px;
}

.primary-profile-chips-box-shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.settings-add-profile-content-remaining-height {
  height: calc(100% - 82px);
}

/* very small Mobile screens */
@media screen and (max-width: 400px) {

  /*Max 5 Notification will be displayed */
  .crop-modal-width {
    max-width: 300px;
  }
}

@media screen and (min-width: 576px) {
  .crop-modal-width {
    max-width: 500px;
  }

  .settings-menu-icon-size {
    height: 48px;
    width: 48px;
  }

  .settings-menu-icon-height {
    height: 24px;
  }

  .settings-primary-profile-horizontal-margin {
    margin-left: 23px;
    margin-right: 23px;
  }

  .settings-secondary-profile-horizontal-margin {
    margin-left: 21px;
    margin-right: 21px;
  }

  .settings-add-profile-icon-height {
    height: 48px;
  }

  .settings-add-profile-active-icon-height {
    height: 66px;
  }

  .settings-notification-icon-count-size {
    height: 18px;
    width: 18px;
  }

  .settings-notification-icon-count-size-large {
    height: 18px;
    width: 26px;
  }

  .font-size-extra-small {
    font-size: 12px;
  }

  .settings-notification-icon-count-size-large {
    bottom: 6px;
    left: 2px;
  }

  .settings-menu-horizontal-ruler {
    height: 1px;
    background-color: #e5e5e5;
    margin-top: -1px;
  }

  .profiles-section-height {
    height: 80px;
  }

  .settings-add-profile-content-remaining-height {
    height: calc(100% - 128px);
  }
}